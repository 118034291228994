var $class="se2--roi-calculator-widget",$name="roiCalculatorWidget",$path="app/partials/roiCalculatorWidget/index.js",$this={$class,$name,$path,};/* eslint-disable no-undef */
import shell from 'app/modules/shell';

import appendAsyncScriptElement from 'app/utilities/appendAsyncScriptElement';

import { popupTriggerId, popupTriggerScriptUrl } from './config';

shell.registerPartial($this, ({ addEventListener, mount }) => {
  mount((element) => {
    let isPopupTriggerLoaded = false;

    addEventListener(element, 'click', () => {
      if (!isPopupTriggerLoaded) {
        appendAsyncScriptElement(popupTriggerScriptUrl).then(() => {
          // NOTE: initIframe & toggleDrawer are functions, which calls from third party script
          initIframe(popupTriggerId);
          toggleDrawer(popupTriggerId);

          isPopupTriggerLoaded = true;
        });
      } else {
        toggleDrawer(popupTriggerId);
      }
    });
  });
});
