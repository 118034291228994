var $class="se2--content-article-slide-carousel",$name="ContentArticleSlideCarousel",$path="app/components/ContentArticleSlideCarousel/index.js",$this={$class,$name,$path,};import lazyHandleOpenModalButtons from 'app/components/utilities/lazyHandleOpenModalButtons';

import shell from 'app/modules/shell';

import 'app/partials/image';
import 'app/partials/roiCalculatorWidget';

import initializeSlider from 'app/components/utilities/initializeSlider';

import setYoutubeVideoThumbnail from 'app/utilities/setYoutubeVideoThumbnail';

import { css } from 'configs';

import contentArticleSlideCarouselSlideSelector from './partials/slide';

export const slidesSelector = '.article-slides';
export const controlsSelector = '.controls';
export const prevButtonSelector = '.slider-button-prev';
export const nextButtonSelector = '.slider-button-next';
export const activeSlideClassName = 'active-slide';
export const articleSlideContainerSelector = '.article-slide-carousel-container';
export const videoBlockSelector = '.video-thumbnail';
export const thumbnailImageSelector = '.thumbnail-image img';
export const titleSelector = '.title';
export const infoBlockContainerSelector = '.info-block-container';
export const paginationBulletClassName = 'pagination-bullet';
export const slideLinkSelector = '.slide-link';
export const interactiveElementsSelector = 'a, .se-rich-text a, button, iframe';

export default shell.registerComponent($this, ({ addEventListener, mount }) => {
  mount((element) => {
    const amountOfSlides = element.querySelectorAll(contentArticleSlideCarouselSlideSelector).length;
    if (amountOfSlides > 1) {
      let currentSlider = null;

      currentSlider = initializeSlider(element, addEventListener, {
        containerSelector: slidesSelector,
        isArticleSlides: true,
        nextButtonSelector,
        paginationBulletSelector: `.${paginationBulletClassName}`,
        prevButtonSelector,
        preventKeyNavigation: true,
        slideSelector: contentArticleSlideCarouselSlideSelector,
        swiperContainerSelector: articleSlideContainerSelector,
        withInteractiveElements: true,
      });

      addEventListener(window, 'resize', currentSlider.onResize);
    }

    const { videoThumbnail, videoPlayButton } = css.classNames;
    setYoutubeVideoThumbnail(element, videoThumbnail);
    lazyHandleOpenModalButtons(addEventListener, element, [videoThumbnail, videoPlayButton]);
  });
});
